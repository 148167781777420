
  <template>
  <b-container fluid class="account-headers accounts-tabs-height">
    <b-row class="pl-4">
      <b-col cols="12" style="margin-bottom: 37px">
        <!-- <a @click="getMyBilling()">biller</a>
        <p v-for="item in myBills" :key="item.id">
          {{ item }}
        </p> -->

        <div class="row billing-table">
          <div class="col-md-12">
            <h3> {{$t("profile.contents.plans&billing.billing.title")}}</h3>
          </div>

          <div class="col-md-6 pr-0">
            <h5 class="first-table">{{$t("profile.contents.plans&billing.billing.holder")}}</h5>
            <h5 class="first-table">{{$t("profile.contents.plans&billing.billing.total")}}</h5>
            <h5 class="first-table">{{$t("profile.contents.plans&billing.billing.status")}}</h5>
            <h5 class="first-table">{{$t("profile.contents.plans&billing.billing.start")}}</h5>
            <h5 class="first-table" style="border-bottom: 1px solid #c4c4c4">
             {{$t("profile.contents.plans&billing.billing.end")}}
            </h5>
          </div>

          <div class="col-md-6 pl-0">
            <h5 class="second-table">
              {{ userMeInfo.name }} {{ userMeInfo.surname }}
            </h5>
            <h5 class="second-table" v-if="!myPlan.cancelled">
              {{ myPlan.plan.info.prices["EUR"] }}
            </h5>
            <h5 class="second-table" v-else>
              - {{ myPlan.plan.info.prices["EUR"] }}
            </h5>

            <h5 class="second-table" v-if="!myPlan.cancelled">{{$t("profile.contents.plans&billing.billing.active")}}</h5>
            <h5 class="second-table" v-else>{{$t("profile.contents.plans&billing.billing.cancelled")}}</h5>

            <h5 class="second-table" v-if="!myPlan.cancelled">
              {{ myPlan.plan.created_at }}
            </h5>
            <h5 class="second-table" v-else>
              {{ myPlan.plan.created_at }}
            </h5>

            <h5 class="second-table-last" v-if="!myPlan.cancelled">
              {{ myPlan.expired_at }}
            </h5>
            <h5
              class="second-table-last"
              v-else
              style="border-bottom: 1px solid #c4c4c4 !important"
            >
              {{ myPlan.expired_at }}
            </h5>
          </div>
        </div>
      </b-col>

      <b-col cols="12">
        <b-button v-show="!myPlan.cancelled" class="billing-cancel">
          {{$t("buttons.cancelplan")}}        </b-button>
      </b-col>

      <b-col cols="12">
        <div class="row billing-table">
          <div class="col-md-12">
            <h3>{{$t("profile.contents.plans&billing.billing.title2")}}</h3>
          </div>
          <div class="col-md-6 pr-0">
            <h5
              class="first-table"
              v-for="item in myBills"
              :key="item.id"
              style="border-bottom: 1px solid #c4c4c4"
            >
              {{ item.created_at }}
            </h5>
          </div>

          <div class="col-md-6 pl-0">
            <h5
              class="second-table"
              v-for="item in myBills"
              :key="item.id"
              style="border-bottom: 1px solid #c4c4c4; color: #198cff"
            >
              {{ item.amount }} {{ item.currency.symbol }}
            </h5>
          </div>
        </div>
      </b-col>

      <!-- <b-col cols="12">
        <div class="row">
          <div class="col-md-12"></div>
          <div class="col-md-6">
            <h5 v-for="item in myBills" :key="item.id">
              {{ item.created_at }}
            </h5>
          </div>
          <div class="col-md-6">
            <h5 v-for="item in myBills" :key="item.id">{{ item.amount }}</h5>
          </div>
        </div>
      </b-col> -->
    </b-row>
  </b-container>
</template>


<script>
import getMyPlans from "@/graphql/me/profile/getMyPlan.graphql";
import getMyBills from "@/graphql/me/profile/getMyPayments.gql";

export default {
  data() {
    return {
      myplan: {},
      myBills: [],
      page: 1,
      limit: 12,
      userMeInfo: [],
    };
  },
  methods: {
    async getMyPlan() {
      try {
        let data = await this.$apollo.query(getMyPlans);
        let response = data("**.get");

        this.$forceUpdate();
        this.myPlan = response;
      } catch (e) {
        
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
    async getMyBilling() {
      try {
        let data = await this.$apollo.query(getMyBills, {
          filters: {},
          page: this.page,
          limit: this.limit,
        });
        let response = data("**.list");

        this.$forceUpdate();
        this.myBills = response;
      } catch (e) {
        
        return this.$bvToast.toast(this.$t(e.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
         autoHideDelay: 1350,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.getMyPlan();
    this.getMyBilling();
    let userInfo = this.GET_USER_INFO;
    this.userMeInfo = userInfo;
  },
};
</script>